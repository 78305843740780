* {
    margin: 0;
    padding: 0;
    outline: 0;
    color: #fff;
    line-height: 1.5;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

@mixin flexCenter($align_items, $justify_content, $flex_direction) {
    display: flex;
    align-items: $align_items;
    justify-content: $justify_content;
    flex-direction: $flex_direction;
}

html {
    width: 100%;
    height: 100vh;
    scroll-behavior: smooth;
    background-color: #1C1C1C;
}

svg {
    fill: #9c9c9c;
    image-rendering: pixelated;
    transition: all ease-in-out 800ms;

    &:hover {
        fill: #ffffff;
    }
}

.btn-section {
    color: #fff;
    line-height: 1;
    font-weight: 500;
    margin: 1.5rem 0;
    overflow: hidden !important;
    font-size: 1.2rem;
    background-color: transparent;
    position: relative;
    padding: 1.25rem 1.75rem;
    border: 1px solid #fff;
    // transform: translate(20%, 50%) translate3d(0, 0, 0);
    transition: all ease-in-out 500ms;

    &:hover {
        span {
            width: 100%;
            left: calc(50% - 15px);
        }
    }

    span {
        top: 0;
        left: -15px;
        content: '';
        height: 100%;
        color: #1c1c1c;
        position: absolute;
        width: calc(50% + 15px);
        transform: skew(-20deg);
        background-color: #fff;
        mix-blend-mode: difference;
        transition: all 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
}

main {

    #portfolio {
        margin: 65px 0;
    }

    section {
        height: 100vh;
        padding: 30px 100px 65px 100px;

        h1[class*="__title"] {
            line-height: 1;
            cursor: default;
            font-size: 4rem;
            margin: 25px 0 1.25rem 0;
            text-shadow: 5px 5px 0px #3c4043c4;
        }

        p[class*="__text"] {
            max-width: 800px;
            font-size: 1.2rem;
        }
    }
}

@import '../components/Home/_home';
@import '../Effects/_fadeInStyles';
@import '../components/About/_about';
@import '../components/Header/_header';
@import '../components/Footer/_footer';
@import '../components/Contact/_contact';
@import '../components/Portfolio/_portfolio';
@import '../components/SideIcones/_sideIcones';

// Smartphone Screen
@media only screen and (min-width: 100px) and (max-width: 600px) {
    $margin-page: 1.5rem;

    main {
        section {

            article {
                width: 100%;
            }

            h1[class*="__title"] {
                font-size: 4rem;
            }

            padding: 30px $margin-page 65px $margin-page;

            p[class*="__text"] {
                font-size: 1.3rem;
            }
        }
    }
}

// Tablet Screnn
@media only screen and (min-width: 600px) and (max-width: 1024px) {
    $margin-page: 1.5rem;

    main {
        section {
            h1[class*="__title"] {
                font-size: 4rem;
            }

            padding: 30px $margin-page 65px $margin-page;

            p[class*="__text"] {
                font-size: 1.5rem;
            }
        }
    }
}

// Tablet LandingScape
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    $margin-page: 50px;

    main {
        section {
            padding: 30px $margin-page 65px $margin-page;
        }
    }
}