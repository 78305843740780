#footer {
    padding: 0.5rem 0;
    .footer__list {
        .footer__item {
            .footer__copyrights {
                @include flexCenter(center, center, row);

                &,
                a {
                    color: #afafaf;
                }

                .footer__copyrights__link{
                    margin: 0 5px 0 0;
                    &:hover {
                        color: #e4e4e4;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    #footer {
        .footer__list {
            .footer__item {
                .footer__copyrights {
                    @include flexCenter(center, center, column);
                }
            }
        }
    }
}