.--fadetoleft {
    opacity: 0;
    animation: fadeToLeft 2.2s;
}

.--fadetoright {
    opacity: 0;
    animation: fadetoright 2.2s;
}

.--opacity {
    opacity: 1 !important;
}

.--fadeinup {
    opacity: 0;
    animation: fadeinup 1.5s 150ms both;
}

@keyframes fadeinup {
    from {
        opacity: 0;
        transform: translate3d(0, 80px, 0)
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 100%;
    }
}

@keyframes fadeToLeft {
    0% {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }

    25% {
        opacity: 10%;
    }

    100% {
        opacity: 100%;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadetoright {
    0% {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }

    25% {
        opacity: 10%;
    }

    100% {
        opacity: 100%;
        transform: translate3d(0, 0, 0);
    }
}