// Cube border
.cube__outter .cube__face {
    border: 1px solid #3c404331;
    animation: outterStroke 50s linear infinite alternate;
}

.cube__inner .cube__face {
    border: 1px solid #3c40439a;
    animation: innerStroke 45s linear infinite alternate;
}

.cube__inner__inner .cube__face {
    border: 1px solid #3c4043;
    animation: innerInnerStroke 40s linear infinite alternate;
}

@keyframes outterStroke {
    0% {
        border-radius: 2.5%;
        border-color: #3c404331;
    }

    100% {
        border-radius: 60%;
        border-color: #3c404320;
    }
}

@keyframes innerStroke {
    0% {
        border-radius: 2.5%;
        border-color: #3c40439a;
    }

    100% {
        border-radius: 60%;
        border-color: #3c40436d;
    }
}

@keyframes innerInnerStroke {
    0% {
        border-radius: 2.5%;
        border-color: #3c4043;
        box-shadow: 1px 1px 1px 1px #ffffff00;
    }

    100% {
        border-radius: 60%;
        border-color: #ffffff49;
        box-shadow: 1px 1px 4px 1px #ffffff73;
    }
}

// Cube rotation
.cube__outter {
    animation: outter 50s linear infinite;
    transform: translateZ(-50px) rotateY(0deg) rotateX(0deg) rotateZ(0deg);
}

.cube__inner {
    animation: inner 45s linear infinite;
    transform: translateZ(-50px) rotateY(0deg) rotateX(0deg) rotateZ(45deg);
}

.cube__inner__inner {
    animation: innerInner 40s linear infinite;
    transform: translateZ(-50px) rotateY(-360deg) rotateX(-90deg) rotateZ(360deg);
}

@keyframes outter {
    0% {
        transform: translateZ(-50px) rotateY(0deg) rotateX(0deg) rotateZ(0deg);
    }

    100% {
        transform: translateZ(-50px) rotateY(360deg) rotateX(360deg) rotateZ(360deg);

    }
}

@keyframes inner {
    0% {
        transform: translateZ(-50px) rotateY(0deg) rotateX(0deg) rotateZ(0deg);
    }

    100% {
        transform: translateZ(-50px) rotateY(180deg) rotateX(180deg) rotateZ(-360deg)
    }
}

@keyframes innerInner {
    0% {
        transform: translateZ(-50px) rotateY(0deg) rotateX(0deg) rotateZ(0deg);
    }

    100% {
        transform: translateZ(-50px) rotateY(-360deg) rotateX(-360deg) rotateZ(360deg)
    }
}

// Cube fadein
@keyframes fadeCubeInner {
    to {
        opacity: 100%;
        transform: scale(145%);
    }

    from {
        opacity: 0%;
        transform: scale(0);
    }
}

@keyframes fadeCubeInnerInner {
    to {
        opacity: 100%;
        transform: scale(60%);
    }

    from {
        opacity: 0%;
        transform: scale(0);
    }
}

@keyframes fadeCubeOutter {
    to {
        opacity: 100%;
        transform: scale(230%);
    }

    from {
        opacity: 0%;
        transform: scale(0);
    }
}