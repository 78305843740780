#side__icones {

    .right__side__icones {
        right: 0px;
        // display: block;
        display: none;
        // opacity: 1;
        z-index: 4;

        // .side__icone__list {
        //     .side__icone__item {
        //         &.--fadeinup {
        //             transform: translate3d(0, 80px, 0)
        //         }
        //     }
        // }
    }

    .left__side__icones {
        left: 0px;

        // .side__icone__item{
        //     animation: teste 2s;
        // }

        &.--fadeinup {
            transform: translate3d(0, 80px, 0)
        }
    }

    .left__side__icones,
    .right__side__icones {
        bottom: 25px;
        width: 100px;
        position: fixed;
        transition: bottom ease-in-out 500ms;

        .side__icone__list {
            width: 100%;
            padding-right: 5px;
            @include flexCenter(center, center, column);

            .side__icone__item {
                &:nth-child(even) {
                    padding: 5px 0;
                }

                &:nth-child(1),
                &:nth-child(3) {
                    svg {
                        width: 25px;
                        height: 25px;
                    }
                }

                a {
                    display: block;


                    &:hover {
                        svg {
                            fill: #FFF;
                        }
                    }
                }

                .side__link__image {
                    opacity: 0;
                    width: 35px;
                    height: 35px;
                    animation-delay: 150ms;

                    &:hover {
                        fill: #FFF;
                    }
                }
            }
        }
    }
}

// Tablet LandingScape
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    #side__icones {

        .right__side__icones,
        .left__side__icones {
            bottom: 0;

            .side__icone__list {
                padding: 0;

                .side__icone__item {

                    .side__link__image {
                        width: 35px;
                        height: 35px;
                    }
                }
            }

        }

        .left__side__icones {
            left: 0;
            width: 50px;
        }

    }
}

// SmartPhone and Tablet Screen
@media only screen and (min-width: 100px) and (max-width: 1024px) {
    #footer #side__icones {
        width: 100%;
        padding: 15px 0;
        position: relative;
        @include flexCenter(center, center, row);

        .left__side__icones {
            bottom: 0;
            position: relative;

            .side__icone__list {
                width: 100%;
                padding-right: 5px;
                @include flexCenter(center, center, row);

                .side__icone__item {
                    &:nth-child(even) {
                        padding: 0 15px;
                    }
                }

                .side__link__image {
                    opacity: 1;
                    animation: unset;
                    transform: translate3d(0, 0, 0);
                }
            }
        }
    }
}