* {
  margin: 0;
  padding: 0;
  outline: 0;
  color: #fff;
  line-height: 1.5;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

html {
  width: 100%;
  height: 100vh;
  scroll-behavior: smooth;
  background-color: #1C1C1C;
}

svg {
  fill: #9c9c9c;
  image-rendering: pixelated;
  transition: all ease-in-out 800ms;
}
svg:hover {
  fill: #ffffff;
}

.btn-section {
  color: #fff;
  line-height: 1;
  font-weight: 500;
  margin: 1.5rem 0;
  overflow: hidden !important;
  font-size: 1.2rem;
  background-color: transparent;
  position: relative;
  padding: 1.25rem 1.75rem;
  border: 1px solid #fff;
  transition: all ease-in-out 500ms;
}
.btn-section:hover span {
  width: 100%;
  left: calc(50% - 15px);
}
.btn-section span {
  top: 0;
  left: -15px;
  content: "";
  height: 100%;
  color: #1c1c1c;
  position: absolute;
  width: calc(50% + 15px);
  transform: skew(-20deg);
  background-color: #fff;
  mix-blend-mode: difference;
  transition: all 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

main #portfolio {
  margin: 65px 0;
}
main section {
  height: 100vh;
  padding: 30px 100px 65px 100px;
}
main section h1[class*=__title] {
  line-height: 1;
  cursor: default;
  font-size: 4rem;
  margin: 25px 0 1.25rem 0;
  text-shadow: 5px 5px 0px rgba(60, 64, 67, 0.768627451);
}
main section p[class*=__text] {
  max-width: 800px;
  font-size: 1.2rem;
}

#home {
  padding-top: 0;
  overflow: hidden;
  margin-top: 55px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: default;
}
#home #homeCheckbox {
  display: none;
}
#home #homeCheckbox:checked ~ #side__icones .right__side__icones .side__icone__list .lang:not(.lang:first-child) {
  animation: dropDown 500ms;
  transform: translate3d(0, 0px, 0);
  width: 20px;
  height: 25px;
  opacity: 1;
}
#home #side__icones .right__side__icones {
  top: 30px;
  display: block;
  position: absolute;
}
#home #side__icones .right__side__icones .side__icone__list {
  padding: 0;
  height: auto;
  overflow: unset;
  justify-content: flex-start;
}
#home #side__icones .right__side__icones .side__icone__list .side__icone__item,
#home #side__icones .right__side__icones .side__icone__list .side__icone__item label,
#home #side__icones .right__side__icones .side__icone__list .side__icone__item .side__link {
  width: 100px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
#home #side__icones .right__side__icones .side__icone__list .side__link__image {
  top: 0;
  z-index: 9;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
}
#home #side__icones .right__side__icones .side__icone__list .lang {
  cursor: pointer;
  color: #636363;
}
#home #side__icones .right__side__icones .side__icone__list .lang.active {
  color: #fff;
}
#home #side__icones .right__side__icones .side__icone__list .lang:not(.lang:first-child) {
  opacity: 1;
  width: 0px;
  height: 0px;
  overflow: hidden;
  text-align: center;
  transform: translate3d(0, -55px, 0);
  transition: opacity ease-in-out 500ms, transform ease-in-out 500ms, width 500ms 100ms, height 500ms 100ms;
}
#home .home__content {
  z-index: 5;
  display: flex;
  align-items: flex-start;
  justify-content: default;
  flex-direction: column;
}
#home .home__content .home__content__title,
#home .home__content .home__content__text {
  margin: 0 0 1rem 0;
}
#home .home__content .home__content__title {
  font-size: 6rem;
  height: -moz-min-content;
  height: min-content;
}
#home .home__content .home__content__text a {
  font-weight: 700;
}

@keyframes dropDown {
  to {
    opacity: 1;
    width: 20px;
    height: 25px;
    transform: translate3d(0, 0px, 0);
  }
  from {
    opacity: 0;
    width: 0px;
    height: 0px;
    transform: translate3d(0, -55px, 0);
  }
}
@media only screen and (max-width: 500px) {
  #home .home__content {
    margin-bottom: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
  #home .home__content .home__content__title {
    font-size: 4.2rem;
  }
}
.--fadetoleft {
  opacity: 0;
  animation: fadeToLeft 2.2s;
}

.--fadetoright {
  opacity: 0;
  animation: fadetoright 2.2s;
}

.--opacity {
  opacity: 1 !important;
}

.--fadeinup {
  opacity: 0;
  animation: fadeinup 1.5s 150ms both;
}

@keyframes fadeinup {
  from {
    opacity: 0;
    transform: translate3d(0, 80px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 100%;
  }
}
@keyframes fadeToLeft {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  25% {
    opacity: 10%;
  }
  100% {
    opacity: 100%;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadetoright {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  25% {
    opacity: 10%;
  }
  100% {
    opacity: 100%;
    transform: translate3d(0, 0, 0);
  }
}
#about {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
#about h1 {
  width: 100%;
}
#about .about__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
#about .about__content .about__text {
  max-width: 900px;
  margin-bottom: 1.25rem;
}
#about .about__content .image__profissional__image {
  width: 450px;
  position: relative;
  margin: 0 0 0 7rem;
  align-self: flex-start;
}
#about .about__content .image__profissional__image div,
#about .about__content .image__profissional__image img {
  border-radius: 2.5px;
}
#about .about__content .image__profissional__image img {
  z-index: 6;
  width: 450px;
  height: 450px;
  position: relative;
  box-shadow: 2px 2px 0px 2px #1c1c1c;
}
#about .about__content .image__profissional__image div {
  z-index: 7;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(28, 28, 28, 0.5);
  transition: background-color ease-in-out 500ms;
}
#about .about__content .image__profissional__image div:hover {
  background-color: rgba(28, 28, 28, 0);
}
#about .about__content .image__profissional__image:hover::after {
  top: 2.5px;
  left: 7.5px;
  border-color: rgba(54, 55, 57, 0.5);
}
#about .about__content .image__profissional__image::after {
  top: 5px;
  left: 15px;
  margin: 5px;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all ease-in-out 500ms;
  border: 2px solid rgb(54, 58, 59);
}
#about .about__content .about__technologies {
  width: 65%;
  margin: 3rem 0 0 15px;
  display: flex;
  align-items: default;
  justify-content: space-between;
  flex-direction: row;
}
#about .about__content .about__technologies .about__technologies__list {
  height: 100%;
}
#about .about__content .about__technologies .about__technologies__list .technologies__item__header {
  color: #ffffff;
  font-weight: bold;
  list-style: square;
  margin-bottom: 0.5rem;
}
#about .about__content .about__technologies .about__technologies__list .technologies__item {
  font-weight: bold;
  position: relative;
  color: rgba(255, 255, 255, 0.8666666667);
}

.technologies__cubes {
  z-index: 0;
  width: 50%;
  height: 500px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.technologies__cubes .scene:nth-child(1),
.technologies__cubes .scene:nth-child(2),
.technologies__cubes .scene:nth-child(3) {
  width: 200px;
  height: 200px;
  perspective: 400px;
}
.technologies__cubes .scene:nth-child(1),
.technologies__cubes .scene:nth-child(3) {
  position: absolute;
  transform: translate(50%, 50%);
}
.technologies__cubes .scene:nth-child(1) {
  animation: fadeCubeOutter 1.4s linear;
  transition: transform ease-out 1.2s;
}
.technologies__cubes .scene:nth-child(1).--fadeincube {
  transform: scale(230%);
}
.technologies__cubes .scene:nth-child(2) {
  animation: fadeCubeInner 1.8s linear;
  transition: transform ease-out 1.8s;
}
.technologies__cubes .scene:nth-child(2).--fadeincube {
  transform: scale(145%);
}
.technologies__cubes .scene:nth-child(3) {
  animation: fadeCubeInnerInner 2s linear;
  transition: transform ease-out 2s;
}
.technologies__cubes .scene:nth-child(3).--fadeincube {
  transform: scale(60%);
}

.cube__outter,
.cube__inner,
.cube__inner__inner {
  width: 200px;
  height: 200px;
  position: absolute;
  transform-style: preserve-3d;
}
.cube__outter .cube__face,
.cube__inner .cube__face,
.cube__inner__inner .cube__face {
  width: 200px;
  height: 200px;
  position: absolute;
  line-height: 200px;
}
.cube__outter .cube__face--front,
.cube__inner .cube__face--front,
.cube__inner__inner .cube__face--front {
  transform: rotateY(0deg) translateZ(100px);
}
.cube__outter .cube__face--right,
.cube__inner .cube__face--right,
.cube__inner__inner .cube__face--right {
  transform: rotateY(90deg) translateZ(100px);
}
.cube__outter .cube__face--back,
.cube__inner .cube__face--back,
.cube__inner__inner .cube__face--back {
  transform: rotateY(180deg) translateZ(100px);
}
.cube__outter .cube__face--left,
.cube__inner .cube__face--left,
.cube__inner__inner .cube__face--left {
  transform: rotateY(-90deg) translateZ(100px);
}
.cube__outter .cube__face--top,
.cube__inner .cube__face--top,
.cube__inner__inner .cube__face--top {
  transform: rotateX(90deg) translateZ(100px);
}
.cube__outter .cube__face--bottom,
.cube__inner .cube__face--bottom,
.cube__inner__inner .cube__face--bottom {
  transform: rotateX(-90deg) translateZ(100px);
}

.cube__outter .cube__face {
  border: 1px solid rgba(60, 64, 67, 0.1921568627);
  animation: outterStroke 50s linear infinite alternate;
}

.cube__inner .cube__face {
  border: 1px solid rgba(60, 64, 67, 0.6039215686);
  animation: innerStroke 45s linear infinite alternate;
}

.cube__inner__inner .cube__face {
  border: 1px solid #3c4043;
  animation: innerInnerStroke 40s linear infinite alternate;
}

@keyframes outterStroke {
  0% {
    border-radius: 2.5%;
    border-color: rgba(60, 64, 67, 0.1921568627);
  }
  100% {
    border-radius: 60%;
    border-color: rgba(60, 64, 67, 0.1254901961);
  }
}
@keyframes innerStroke {
  0% {
    border-radius: 2.5%;
    border-color: rgba(60, 64, 67, 0.6039215686);
  }
  100% {
    border-radius: 60%;
    border-color: rgba(60, 64, 67, 0.4274509804);
  }
}
@keyframes innerInnerStroke {
  0% {
    border-radius: 2.5%;
    border-color: #3c4043;
    box-shadow: 1px 1px 1px 1px rgba(255, 255, 255, 0);
  }
  100% {
    border-radius: 60%;
    border-color: rgba(255, 255, 255, 0.2862745098);
    box-shadow: 1px 1px 4px 1px rgba(255, 255, 255, 0.4509803922);
  }
}
.cube__outter {
  animation: outter 50s linear infinite;
  transform: translateZ(-50px) rotateY(0deg) rotateX(0deg) rotateZ(0deg);
}

.cube__inner {
  animation: inner 45s linear infinite;
  transform: translateZ(-50px) rotateY(0deg) rotateX(0deg) rotateZ(45deg);
}

.cube__inner__inner {
  animation: innerInner 40s linear infinite;
  transform: translateZ(-50px) rotateY(-360deg) rotateX(-90deg) rotateZ(360deg);
}

@keyframes outter {
  0% {
    transform: translateZ(-50px) rotateY(0deg) rotateX(0deg) rotateZ(0deg);
  }
  100% {
    transform: translateZ(-50px) rotateY(360deg) rotateX(360deg) rotateZ(360deg);
  }
}
@keyframes inner {
  0% {
    transform: translateZ(-50px) rotateY(0deg) rotateX(0deg) rotateZ(0deg);
  }
  100% {
    transform: translateZ(-50px) rotateY(180deg) rotateX(180deg) rotateZ(-360deg);
  }
}
@keyframes innerInner {
  0% {
    transform: translateZ(-50px) rotateY(0deg) rotateX(0deg) rotateZ(0deg);
  }
  100% {
    transform: translateZ(-50px) rotateY(-360deg) rotateX(-360deg) rotateZ(360deg);
  }
}
@keyframes fadeCubeInner {
  to {
    opacity: 100%;
    transform: scale(145%);
  }
  from {
    opacity: 0%;
    transform: scale(0);
  }
}
@keyframes fadeCubeInnerInner {
  to {
    opacity: 100%;
    transform: scale(60%);
  }
  from {
    opacity: 0%;
    transform: scale(0);
  }
}
@keyframes fadeCubeOutter {
  to {
    opacity: 100%;
    transform: scale(230%);
  }
  from {
    opacity: 0%;
    transform: scale(0);
  }
}
@media only screen and (max-width: 1280px) {
  #about {
    height: auto;
  }
  #about .about__content .about__technologies {
    width: auto;
  }
}
@media only screen and (min-width: 100px) and (max-width: 850px) {
  #about {
    height: auto;
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  #about .about__content {
    justify-content: center;
    flex-direction: column;
  }
  #about .about__content .image__profissional__image {
    width: 100%;
    margin: 2rem 0 0 0;
    position: relative;
  }
  #about .about__content .image__profissional__image img {
    width: 100%;
    height: 100%;
  }
  #about .about__content .image__profissional__image::after {
    top: 5px;
    left: 5px;
    margin: 0;
    content: "";
  }
  #about .about__content .image__profissional__image:hover::after {
    top: 2.5px;
    left: 2.5px;
    border-color: rgba(54, 55, 57, 0.5);
  }
  #about .about__content .about__technologies {
    flex-wrap: wrap;
    display: flex;
    align-items: unset;
    justify-content: space-between;
    flex-direction: row;
  }
  #about .about__content .about__technologies .about__technologies__list:first-child {
    margin-right: 0;
    margin-bottom: 5px;
  }
  #about .about__text {
    width: 100%;
  }
}
#header {
  top: 0;
  z-index: 10;
  width: 100%;
  height: auto;
  position: fixed;
  overflow: hidden;
  background-color: #1c1c1c;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.2);
}
#header #menuCheck {
  display: none;
}
#header .header__navbar {
  height: 55px;
  overflow: hidden;
  padding: 0.5rem 1rem;
  transition: all ease-in-out 200ms;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
#header .header__navbar.scroll-down {
  height: 0px !important;
  padding: 0rem 1rem !important;
}
#header .header__navbar .header__navbar__list .header__navbar__item .header__navbar__brand {
  height: 40px;
  display: block;
}
#header .header__navbar .header__navbar__list .header__navbar__brand__image {
  width: 40px;
  height: 40px;
}
#header .header__navbar .header__navbar__list:first-child .header__navbar__item:last-child {
  display: none;
}
#header .header__navbar .header__navbar__list:first-child .header__navbar__item:last-child label {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
#header .header__navbar .header__navbar__list:first-child .header__navbar__item:last-child label .header__navbar__svg {
  width: 40px;
  height: 40px;
}
#header .header__navbar .header__navbar__list:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
#header .header__navbar .header__navbar__list:nth-child(2) .header__navbar__item .header__navbar__link {
  font-size: 1.3rem;
  color: #6a6b6c;
  transition: color ease-out 250ms;
}
#header .header__navbar .header__navbar__list:nth-child(2) .header__navbar__item .header__navbar__link.active {
  color: #fff;
}
#header .header__navbar .header__navbar__list:nth-child(2) .header__navbar__item .header__navbar__link:hover {
  color: #fff;
}
#header .header__navbar .header__navbar__list:nth-child(2) .header__navbar__item:nth-child(even) {
  margin: 0 1.2rem;
}
#header .header__navbar .header__navbar__list:nth-child(3) {
  display: none;
}

@media only screen and (max-width: 500px) {
  #header #menuCheck ~ .header__navbar .header__navbar__svg--front {
    fill: #fff;
  }
  #header #menuCheck ~ .header__navbar .header__navbar__svg--back {
    fill: #3c4043;
  }
  #header #menuCheck:checked ~ .header__navbar .header__navbar__svg--front {
    fill: #3c4043;
  }
  #header #menuCheck:checked ~ .header__navbar .header__navbar__svg--back {
    fill: #fff;
  }
  #header #menuCheck:checked ~ .header__navbar {
    padding: 1.5rem;
    height: 300px;
  }
  #header .header__navbar {
    height: 65px;
    padding: 1rem;
    flex-direction: column;
  }
  #header .header__navbar .header__navbar__list:first-child {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
  #header .header__navbar .header__navbar__list:first-child .header__navbar__item:last-child {
    display: flex;
    position: relative;
  }
  #header .header__navbar .header__navbar__list:first-child .header__navbar__item:last-child .header__navbar__svg {
    transition: fill ease-in-out 500ms;
  }
  #header .header__navbar .header__navbar__list:first-child .header__navbar__item:last-child .header__navbar__svg--back {
    fill: #3c4043;
  }
  #header .header__navbar .header__navbar__list:first-child .header__navbar__item:last-child .header__navbar__svg--front {
    top: -2px;
    left: -2px;
    fill: #fff;
    cursor: pointer;
    position: absolute;
  }
  #header .header__navbar .header__navbar__list:first-child .header__navbar__item:last-child .header__navbar__svg--front:hover {
    fill: #3c4043;
  }
  #header .header__navbar .header__navbar__list:first-child .header__navbar__item:last-child .header__navbar__svg--front:hover ~ .header__navbar__svg--back {
    fill: #fff;
  }
  #header .header__navbar .header__navbar__list:nth-child(2) {
    width: 100%;
    flex-direction: column;
  }
  #header .header__navbar .header__navbar__list:nth-child(2) .header__navbar__item,
  #header .header__navbar .header__navbar__list:nth-child(2) .header__navbar__link {
    width: 100%;
    display: block;
  }
  #header .header__navbar .header__navbar__list:nth-child(2) .header__navbar__item {
    padding: 1rem 1rem 1rem 0rem;
    border-bottom: 1px solid #343638;
  }
  #header .header__navbar .header__navbar__list:nth-child(2) .header__navbar__link {
    font-size: 1.5rem !important;
  }
}
#footer {
  padding: 0.5rem 0;
}
#footer .footer__list .footer__item .footer__copyrights {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
#footer .footer__list .footer__item .footer__copyrights,
#footer .footer__list .footer__item .footer__copyrights a {
  color: #afafaf;
}
#footer .footer__list .footer__item .footer__copyrights .footer__copyrights__link {
  margin: 0 5px 0 0;
}
#footer .footer__list .footer__item .footer__copyrights .footer__copyrights__link:hover {
  color: #e4e4e4;
}

@media only screen and (max-width: 500px) {
  #footer .footer__list .footer__item .footer__copyrights {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
#contact {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}
#contact .contact__header .contact__header__title {
  text-align: center;
}
#contact .contact__header .contact__header__text {
  text-align: center;
}
#contact .contact__form {
  width: 100%;
  max-width: 800px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: default;
  flex-direction: column;
}
#contact .contact__form .contact__inputs {
  width: 100%;
  display: flex;
}
#contact .contact__form .contact__inputs input:nth-child(even) {
  margin-left: 5px;
}
#contact .contact__form .form__text,
#contact .contact__form .form__message,
#contact .contact__form #from__submit {
  width: 100%;
  height: 60px;
  padding: 15px;
  font-size: 1rem;
  color: #cccccc;
  margin-bottom: 5px;
  background-color: #171717;
  border: 1px solid rgba(52, 54, 56, 0.2509803922);
}
#contact .contact__form #from__submit {
  cursor: pointer;
  transition: all ease-in-out 500ms;
}
#contact .contact__form #from__submit:hover {
  color: #fff;
  background-color: #121212;
}
#contact .contact__form .form__message {
  height: 200px;
}

@media only screen and (max-width: 500px) {
  #contact {
    padding-bottom: 0;
  }
  #contact .contact__form .form__text {
    font-size: 1rem;
  }
  #contact .contact__form .contact__inputs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  #contact .contact__form .contact__inputs input:nth-child(even) {
    margin-left: 0;
  }
}
@media only screen and (max-width: 850px) {
  #contact {
    padding-bottom: 0;
  }
}
#portfolio {
  height: auto;
}
#portfolio .portfolio__text {
  margin-bottom: 4rem;
}
#portfolio article article {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#portfolio #card-container, #portfolio #card-container .smallerProjects {
  gap: 5rem;
  padding: 1rem;
  display: grid;
  overflow: hidden;
  position: relative;
  grid-template-columns: repeat(1, 100%);
}
#portfolio #card-container .card-lg {
  opacity: 0;
  height: 450px;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  border: 1px solid rgba(23, 23, 23, 0.4705882353);
  box-shadow: 5px 5px 10px 5px #171717;
}
#portfolio #card-container .card-lg .icons {
  right: 10px;
  bottom: 10px;
  position: absolute;
}
#portfolio #card-container .card-lg .card-lg__github__link svg {
  width: 45px;
  height: 45px;
}
#portfolio #card-container .card-lg .card-lg__page__link {
  display: none;
}
#portfolio #card-container .card-lg:nth-child(even) {
  direction: rtl;
}
#portfolio #card-container .card-lg:nth-child(even) .icons {
  left: 10px;
  right: unset;
  transform: scaleX(-1);
}
#portfolio #card-container .card-lg:nth-child(even) .card-lg__content__info {
  direction: ltr;
}
#portfolio #card-container .card-lg .card-lg__content {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: default;
  flex-direction: row;
}
#portfolio #card-container .card-lg .card-lg__content .card-lg__logo,
#portfolio #card-container .card-lg .card-lg__content .card-lg__content__info {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
#portfolio #card-container .card-lg .card-lg__content .card-lg__logo {
  width: 45%;
  height: 100%;
  position: relative;
}
#portfolio #card-container .card-lg .card-lg__content .card-lg__logo .card-lg__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all ease-in 500ms;
  background-color: rgba(28, 28, 28, 0.5);
}
#portfolio #card-container .card-lg .card-lg__content .card-lg__logo .card-lg__overlay:hover {
  background-color: rgba(28, 28, 28, 0);
}
#portfolio #card-container .card-lg .card-lg__content .card-lg__logo .card-lg__logo__image {
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
     object-fit: fill;
}
#portfolio #card-container .card-lg .card-lg__content .card-lg__content__info {
  width: 55%;
  height: 100%;
  padding: 0 2.25rem;
  display: flex;
  align-items: unset;
  justify-content: space-evenly;
  flex-direction: column;
  background-color: #171717;
}
#portfolio #card-container .card-lg .card-lg__content .card-lg__content__info .card-lg__content__title {
  margin-top: 0;
  margin-bottom: 1.25rem;
}
#portfolio #card-container .card-lg .card-lg__content .card-lg__content__info .card-lg__content__text {
  margin-left: 1.75rem;
}
#portfolio #card-container .card-lg .card-lg__content .card-lg__content__info .card-lg__content__technologies {
  width: 100%;
}
#portfolio #card-container .card-lg .card-lg__content .card-lg__content__info .card-lg__content__technologies .card-lg__content__technologies__list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
#portfolio #card-container .card-lg .card-lg__content .card-lg__content__info .card-lg__content__technologies .card-lg__content__technologies__list li {
  font-weight: 700;
}
#portfolio #card-container .card-lg .card-lg__content .card-lg__content__info .card-lg__content__technologies .card-lg__content__technologies__list li:nth-child(even) {
  padding: 1rem;
}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  #portfolio #card-lg-container {
    padding: 0;
  }
  #portfolio #card-lg-container .card-lg {
    opacity: 1;
    height: auto;
  }
  #portfolio #card-lg-container .card-lg .card-lg__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  #portfolio #card-lg-container .card-lg .card-lg__content .card-lg__logo,
  #portfolio #card-lg-container .card-lg .card-lg__content .card-lg__content__info {
    width: 100%;
  }
  #portfolio #card-lg-container .card-lg .card-lg__content .card-lg__content__info {
    padding: 2.25rem;
  }
}
@media only screen and (min-width: 100px) and (max-width: 1100px) {
  #portfolio #card-container, #portfolio #card-container .smallerProjects {
    padding: 0;
  }
  #portfolio #card-container .card-lg {
    opacity: 0;
    height: auto;
    overflow: hidden;
    box-shadow: unset;
  }
  #portfolio #card-container .card-lg:nth-child(even) {
    direction: unset;
  }
  #portfolio #card-container .card-lg:nth-child(even) .icons {
    left: unset;
    direction: unset;
    transform: scaleX(1);
  }
  #portfolio #card-container .card-lg:nth-child(even) .card-lg__content__info {
    direction: unset;
  }
  #portfolio #card-container .card-lg .card-lg__content {
    flex-direction: column;
    justify-content: space-between;
  }
  #portfolio #card-container .card-lg .card-lg__content .card-lg__logo {
    display: none;
  }
  #portfolio #card-container .card-lg .card-lg__content .card-lg__content__info {
    width: 100%;
    padding: 1.75rem;
    align-items: center;
  }
  #portfolio #card-container .card-lg .card-lg__content .card-lg__content__info .icons {
    width: 100%;
    right: unset;
    bottom: unset;
    padding: 15px;
    position: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  #portfolio #card-container .card-lg .card-lg__content .card-lg__content__info .icons .card-lg__github__link svg {
    width: 50px;
    height: 50px;
  }
  #portfolio #card-container .card-lg .card-lg__content .card-lg__content__info .icons .card-lg__page__link {
    width: 45px;
    height: 45px;
    display: block;
    margin-left: 1.25rem;
  }
  #portfolio #card-container .card-lg .card-lg__content .card-lg__content__info .icons .card-lg__page__link svg {
    width: 45px;
    height: 45px;
    padding: 6px;
  }
  #portfolio #card-container .card-lg .card-lg__content .card-lg__content__info .card-lg__content__title {
    margin-top: 0;
    width: 100%;
  }
  #portfolio #card-container .card-lg .card-lg__content .card-lg__content__info .card-lg__content__text {
    margin: 0;
  }
}
@media only screen and (min-width: 100px) and (max-width: 500px) {
  #portfolio #card-container, #portfolio #card-container .smallerProjects {
    padding: 0;
  }
  #portfolio #card-container .card-lg {
    opacity: 1;
    height: auto;
    overflow: hidden;
    box-shadow: unset;
  }
  #portfolio #card-container .card-lg:nth-child(even) {
    direction: unset;
  }
  #portfolio #card-container .card-lg:nth-child(even) .icons {
    left: unset;
    direction: unset;
    transform: scaleX(1);
  }
  #portfolio #card-container .card-lg:nth-child(even) .card-lg__content__info {
    direction: unset;
  }
  #portfolio #card-container .card-lg .card-lg__content {
    flex-direction: column !important;
    justify-content: space-between;
  }
  #portfolio #card-container .card-lg .card-lg__content .card-lg__logo {
    display: none;
  }
  #portfolio #card-container .card-lg .card-lg__content .card-lg__content__info {
    width: 100%;
    padding: 1.75rem;
    align-items: center;
  }
  #portfolio #card-container .card-lg .card-lg__content .card-lg__content__info .icons {
    width: 100%;
    right: unset;
    bottom: unset;
    padding: 15px;
    position: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  #portfolio #card-container .card-lg .card-lg__content .card-lg__content__info .icons .card-lg__github__link svg {
    width: 50px;
    height: 50px;
  }
  #portfolio #card-container .card-lg .card-lg__content .card-lg__content__info .icons .card-lg__page__link {
    width: 45px;
    height: 45px;
    display: block;
    margin-left: 1.25rem;
  }
  #portfolio #card-container .card-lg .card-lg__content .card-lg__content__info .icons .card-lg__page__link svg {
    width: 45px;
    height: 45px;
    padding: 6px;
  }
  #portfolio #card-container .card-lg .card-lg__content .card-lg__content__info .card-lg__content__title {
    margin-top: 0;
    width: 100%;
  }
  #portfolio #card-container .card-lg .card-lg__content .card-lg__content__info .card-lg__content__text {
    margin: 0;
  }
}
#portfolio #card-container .smallerProjects {
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr)) !important;
}
#portfolio #card-container .smallerProjects .card-sm {
  opacity: 0;
  overflow: unset;
  position: relative;
  border-radius: 5px;
  background-color: #171717;
  border: 1px solid rgba(23, 23, 23, 0.4705882353);
  box-shadow: 5px 5px 10px 5px #171717;
}
#portfolio #card-container .smallerProjects .card-sm .card-sm__content {
  height: 100%;
}
#portfolio #card-container .smallerProjects .card-sm .card-sm__content .card-sm__content__info {
  width: 100%;
  height: 100%;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}
#portfolio #card-container .smallerProjects .card-sm .card-sm__content .card-sm__content__info .card-sm__content__title {
  line-height: 1;
  font-size: 2.2rem;
  text-align: justify;
  word-break: break-all;
}
#portfolio #card-container .smallerProjects .card-sm .card-sm__content .card-sm__content__info .card-sm__content__text {
  text-align: left;
  min-height: 230px;
  letter-spacing: 0.095rem;
}
#portfolio #card-container .smallerProjects .card-sm .card-sm__content .card-sm__content__info .icons {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: right;
}
#portfolio #card-container .smallerProjects .card-sm .card-sm__content .card-sm__content__info .icons .card-sm__page__link {
  display: flex;
  margin-right: 10px;
}
#portfolio #card-container .smallerProjects .card-sm .card-sm__content .card-sm__content__info .icons .card-sm__github__link svg {
  width: 45px;
  height: 45px;
}
#portfolio #card-container .smallerProjects .card-sm .card-sm__content .card-sm__content__info .card-sm__content__technologies .card-sm__content__technologies__list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 1.75rem;
}
#portfolio #card-container .smallerProjects .card-sm .card-sm__content .card-sm__content__info .card-sm__content__technologies .card-sm__content__technologies__list li {
  font-weight: 700;
}
#portfolio #card-container .smallerProjects .card-sm .card-sm__content .card-sm__content__info .card-sm__content__technologies .card-sm__content__technologies__list li:nth-child(even) {
  padding: 1rem;
}

@media only screen and (min-width: 100px) and (max-width: 1025px) {
  .card-sm {
    max-width: 100% !important;
  }
}
@media only screen and (max-width: 500px) {
  #portfolio {
    height: auto;
  }
  #portfolio .portfolio__title {
    width: -moz-min-content;
    width: min-content;
  }
  #portfolio .portfolio__text {
    width: 100%;
  }
}
@media only screen and (max-width: 850px) {
  #portfolio .portfolio__text {
    width: 100%;
  }
}
#side__icones .right__side__icones {
  right: 0px;
  display: none;
  z-index: 4;
}
#side__icones .left__side__icones {
  left: 0px;
}
#side__icones .left__side__icones.--fadeinup {
  transform: translate3d(0, 80px, 0);
}
#side__icones .left__side__icones,
#side__icones .right__side__icones {
  bottom: 25px;
  width: 100px;
  position: fixed;
  transition: bottom ease-in-out 500ms;
}
#side__icones .left__side__icones .side__icone__list,
#side__icones .right__side__icones .side__icone__list {
  width: 100%;
  padding-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#side__icones .left__side__icones .side__icone__list .side__icone__item:nth-child(even),
#side__icones .right__side__icones .side__icone__list .side__icone__item:nth-child(even) {
  padding: 5px 0;
}
#side__icones .left__side__icones .side__icone__list .side__icone__item:nth-child(1) svg, #side__icones .left__side__icones .side__icone__list .side__icone__item:nth-child(3) svg,
#side__icones .right__side__icones .side__icone__list .side__icone__item:nth-child(1) svg,
#side__icones .right__side__icones .side__icone__list .side__icone__item:nth-child(3) svg {
  width: 25px;
  height: 25px;
}
#side__icones .left__side__icones .side__icone__list .side__icone__item a,
#side__icones .right__side__icones .side__icone__list .side__icone__item a {
  display: block;
}
#side__icones .left__side__icones .side__icone__list .side__icone__item a:hover svg,
#side__icones .right__side__icones .side__icone__list .side__icone__item a:hover svg {
  fill: #FFF;
}
#side__icones .left__side__icones .side__icone__list .side__icone__item .side__link__image,
#side__icones .right__side__icones .side__icone__list .side__icone__item .side__link__image {
  opacity: 0;
  width: 35px;
  height: 35px;
  animation-delay: 150ms;
}
#side__icones .left__side__icones .side__icone__list .side__icone__item .side__link__image:hover,
#side__icones .right__side__icones .side__icone__list .side__icone__item .side__link__image:hover {
  fill: #FFF;
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  #side__icones .right__side__icones,
  #side__icones .left__side__icones {
    bottom: 0;
  }
  #side__icones .right__side__icones .side__icone__list,
  #side__icones .left__side__icones .side__icone__list {
    padding: 0;
  }
  #side__icones .right__side__icones .side__icone__list .side__icone__item .side__link__image,
  #side__icones .left__side__icones .side__icone__list .side__icone__item .side__link__image {
    width: 35px;
    height: 35px;
  }
  #side__icones .left__side__icones {
    left: 0;
    width: 50px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 1024px) {
  #footer #side__icones {
    width: 100%;
    padding: 15px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  #footer #side__icones .left__side__icones {
    bottom: 0;
    position: relative;
  }
  #footer #side__icones .left__side__icones .side__icone__list {
    width: 100%;
    padding-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  #footer #side__icones .left__side__icones .side__icone__list .side__icone__item:nth-child(even) {
    padding: 0 15px;
  }
  #footer #side__icones .left__side__icones .side__icone__list .side__link__image {
    opacity: 1;
    animation: unset;
    transform: translate3d(0, 0, 0);
  }
}
@media only screen and (min-width: 100px) and (max-width: 600px) {
  main section {
    padding: 30px 1.5rem 65px 1.5rem;
  }
  main section article {
    width: 100%;
  }
  main section h1[class*=__title] {
    font-size: 4rem;
  }
  main section p[class*=__text] {
    font-size: 1.3rem;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  main section {
    padding: 30px 1.5rem 65px 1.5rem;
  }
  main section h1[class*=__title] {
    font-size: 4rem;
  }
  main section p[class*=__text] {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  main section {
    padding: 30px 50px 65px 50px;
  }
}/*# sourceMappingURL=styles.css.map */