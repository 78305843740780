#about {
    @include flexCenter(flex-start, center, column);

    h1 {
        width: 100%;
    }

    .about__content {
        @include flexCenter(center, space-between, row);

        .about__text {
            max-width: 900px;
            margin-bottom: 1.25rem;
        }

        .image__profissional__image {
            width: 450px;
            position: relative;
            margin: 0 0 0 7rem;
            align-self: flex-start;

            div,
            img {
                border-radius: 2.5px;
            }

            img {
                z-index: 6;
                width: 450px;
                height: 450px;
                position: relative;
                box-shadow: 2px 2px 0px 2px #1c1c1c;
            }

            div {
                z-index: 7;
                width: 100%;
                height: 100%;
                position: absolute;
                background-color: rgba(28, 28, 28, 0.500);
                transition: background-color ease-in-out 500ms;

                &:hover {
                    background-color: rgba(28, 28, 28, 0);
                }
            }

            &:hover::after {
                top: 2.5px;
                left: 7.5px;
                border-color: rgba(54, 55, 57, 0.500);
            }

            &::after {
                top: 5px;
                left: 15px;
                margin: 5px;
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                transition: all ease-in-out 500ms;
                border: 2px solid rgba(54, 58, 59, 1);
            }
        }

        .about__technologies {
            width: 65%;
            margin: 3rem 0 0 15px;
            @include flexCenter(default, space-between, row);

            .about__technologies__list {
                height: 100%;

                .technologies__item__header{
                    color: #ffffff;
                    font-weight: bold;
                    list-style: square;
                    margin-bottom: 0.5rem;
                }

                .technologies__item {
                    font-weight: bold;
                    position: relative;
                    color: #ffffffdd;

                }
            }

        }
    }
}

@import '../Cube/_cubeScenes';

@media only screen and (max-width: 1280px) {
    #about {
        height: auto;

        .about__content {
            .about__technologies {
                width: auto;
            }
        }
    }
}

@media only screen and (min-width: 100px) and (max-width: 850px) {
    #about {
        height: auto;
        padding-bottom: 2rem;
        @include flexCenter(center, space-between, column);

        .about__content {
            justify-content: center;
            flex-direction: column;

            .image__profissional__image {
                width: 100%;
                margin: 2rem 0 0 0;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                }

                &::after {
                    top: 5px;
                    left: 5px;
                    margin: 0;
                    content: "";
                }

                &:hover::after {
                    top: 2.5px;
                    left: 2.5px;
                    border-color: rgba(54, 55, 57, 0.500);
                }
            }

            .about__technologies {
                flex-wrap: wrap;
                @include flexCenter(unset, space-between, row);


                .about__technologies__list {

                    &:first-child {
                        margin-right: 0;
                        margin-bottom: 5px;
                    }
                }
            }
        }

        .about__text {
            width: 100%;
        }
    }
}