#card-container {
    gap: 5rem;
    padding: 1rem;
    display: grid;
    overflow: hidden;
    position: relative;
    grid-template-columns: repeat(1, 100%);

    .card-lg {
        opacity: 0;
        height: 450px;
        overflow: hidden;
        position: relative;
        border-radius: 5px;
        border: 1px solid #17171778;
        box-shadow: 5px 5px 10px 5px #171717;

        .icons {
            right: 10px;
            bottom: 10px;
            position: absolute;
        }

        .card-lg__github__link svg {
            width: 45px;
            height: 45px;

        }

        .card-lg__page__link {
            display: none;
        }

        &:nth-child(even) {
            direction: rtl;

            .icons {
                left: 10px;
                right: unset;
                transform: scaleX(-1);
            }

            .card-lg__content__info {
                direction: ltr;
            }
        }

        .card-lg__content {
            height: 100%;
            position: relative;
            @include flexCenter(center, default, row);

            .card-lg__logo,
            .card-lg__content__info {
                @include flexCenter(flex-start, center, column);
            }

            .card-lg__logo {
                width: 45%;
                height: 100%;
                position: relative;

                .card-lg__overlay {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    transition: all ease-in 500ms;
                    background-color: rgba(28, 28, 28, 0.5);

                    &:hover {
                        background-color: rgba(28, 28, 28, 0);
                    }
                }

                .card-lg__logo__image {
                    width: 100%;
                    height: 100%;
                    object-fit: fill;
                }
            }

            .card-lg__content__info {
                width: 55%;
                height: 100%;
                padding: 0 2.25rem;
                @include flexCenter(unset, space-evenly, column);
                background-color: #171717;

                .card-lg__content__title {
                    margin-top: 0;
                    margin-bottom: 1.25rem;
                }

                .card-lg__content__text {
                    margin-left: 1.75rem;
                }

                .card-lg__content__technologies {
                    width: 100%;

                    .card-lg__content__technologies__list {
                        & li {
                            font-weight: 700;

                            &:nth-child(even) {
                                padding: 1rem;
                            }
                        }

                        @include flexCenter(center, center, row);
                    }
                }
            }
        }
    }
}

// Tablet Screnn
@media only screen and (min-width: 600px) and (max-width: 1024px) {
    #card-lg-container {
        padding: 0;

        .card-lg {
            opacity: 1;
            height: auto;

            .card-lg__content {
                @include flexCenter(center, center, column);

                .card-lg__logo,
                .card-lg__content__info {
                    width: 100%;
                }

                .card-lg__content__info {
                    padding: 2.25rem;
                }
            }
        }
    }
}

// Smartphone Screen
@media only screen and (min-width: 100px) and (max-width: 1100px) {
    #card-container {
        padding: 0;

        .card-lg {
            opacity: 0;
            height: auto;
            overflow: hidden;
            box-shadow: unset;

            &:nth-child(even) {
                direction: unset;

                .icons {
                    left: unset;
                    direction: unset;
                    transform: scaleX(1);
                }

                .card-lg__content__info {
                    direction: unset;
                }
            }

            .card-lg__content {
                flex-direction: column;
                justify-content: space-between;

                .card-lg__logo {
                    display: none;
                }

                .card-lg__content__info {
                    width: 100%;
                    padding: 1.75rem;
                    align-items: center;

                    .icons {
                        width: 100%;
                        right: unset;
                        bottom: unset;
                        padding: 15px;
                        position: unset;

                        @include flexCenter(center, center, row);

                        .card-lg__github__link svg {
                            width: 50px;
                            height: 50px;
                        }

                        .card-lg__page__link {
                            width: 45px;
                            height: 45px;
                            display: block;
                            margin-left: 1.25rem;

                            svg {
                                width: 45px;
                                height: 45px;
                                padding: 6px;
                            }
                        }
                    }

                    .card-lg__content__title {
                        margin-top: 0;
                        width: 100%;
                    }

                    .card-lg__content__text {
                        margin: 0;
                    }
                }
            }
        }
    }
}

// Smartphone Screen
@media only screen and (min-width: 100px) and (max-width: 500px) {
    #card-container {
        padding: 0;

        .card-lg {
            opacity: 1;
            height: auto;
            overflow: hidden;
            box-shadow: unset;

            &:nth-child(even) {
                direction: unset;

                .icons {
                    left: unset;
                    direction: unset;
                    transform: scaleX(1);
                }

                .card-lg__content__info {
                    direction: unset;
                }
            }

            .card-lg__content {
                flex-direction: column !important;
                justify-content: space-between;

                .card-lg__logo {
                    display: none;
                }

                .card-lg__content__info {
                    width: 100%;
                    padding: 1.75rem;
                    align-items: center;

                    .icons {
                        width: 100%;
                        right: unset;
                        bottom: unset;
                        padding: 15px;
                        position: unset;

                        @include flexCenter(center, center, row);

                        .card-lg__github__link svg {
                            width: 50px;
                            height: 50px;
                        }

                        .card-lg__page__link {
                            width: 45px;
                            height: 45px;
                            display: block;
                            margin-left: 1.25rem;

                            svg {
                                width: 45px;
                                height: 45px;
                                padding: 6px;
                            }
                        }
                    }

                    .card-lg__content__title {
                        margin-top: 0;
                        width: 100%;
                    }

                    .card-lg__content__text {
                        margin: 0;
                    }
                }
            }
        }
    }
}