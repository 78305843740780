#header {
    top: 0;
    z-index: 10;
    width: 100%;
    height: auto;
    position: fixed;
    overflow: hidden;
    background-color: #1c1c1c;
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.2);

    #menuCheck {
        display: none;
    }

    .header__navbar {
        height: 55px;
        overflow: hidden;
        padding: 0.5rem 1rem;
        transition: all ease-in-out 200ms;
        @include flexCenter(center, space-between, row);

        &.scroll-down {
            height: 0px !important;
            padding: 0rem 1rem !important;
        }

        .header__navbar__list {

            .header__navbar__item .header__navbar__brand{
                height: 40px;
                display: block;
            }

            .header__navbar__brand__image {
                width: 40px;
                height: 40px;
            }

            &:first-child {
                .header__navbar__item:last-child {
                    display: none;

                    label {
                        margin: 0;
                        @include flexCenter(center, center, row);

                        .header__navbar__svg {
                            width: 40px;
                            height: 40px;
                        }
                    }
                }
            }

            &:nth-child(2) {
                @include flexCenter(center, center, row);

                .header__navbar__item {

                    .header__navbar__link {
                        &.active {
                            color: #fff;
                        }

                        font-size: 1.3rem;
                        color: #6a6b6c;
                        transition: color ease-out 250ms;

                        &:hover {
                            color: #fff;
                        }
                    }

                    &:nth-child(even) {
                        margin: 0 1.2rem;
                    }

                }

            }

            &:nth-child(3) {
                display: none;
            }

        }
    }
}

@media only screen and (max-width: 500px) {
    #header {
        #menuCheck {

            &~.header__navbar {
                .header__navbar__svg--front {
                    fill: #fff;
                }

                .header__navbar__svg--back {
                    fill: #3c4043;
                }
            }

            &:checked {

                &~.header__navbar {
                    .header__navbar__svg--front {
                        fill: #3c4043;
                    }

                    .header__navbar__svg--back {
                        fill: #fff;
                    }
                }

                &~.header__navbar {
                    padding: 1.5rem;
                    height: 300px;
                }
            }

        }

        .header__navbar {
            height: 65px;
            padding: 1rem;
            flex-direction: column;

            .header__navbar__list {

                &:first-child {
                    width: 100%;
                    position: relative;
                    @include flexCenter(center, space-between, row);

                    .header__navbar__item:last-child {
                        display: flex;
                        position: relative;

                        .header__navbar__svg {
                            transition: fill ease-in-out 500ms;
                        }

                        .header__navbar__svg--back {
                            fill: #3c4043;
                        }

                        .header__navbar__svg--front {
                            top: -2px;
                            left: -2px;
                            fill: #fff;
                            cursor: pointer;
                            position: absolute;

                            &:hover {
                                fill: #3c4043;

                                &~.header__navbar__svg--back {
                                    fill: #fff;

                                }
                            }
                        }
                    }
                }

                &:nth-child(2) {
                    width: 100%;
                    flex-direction: column;

                    .header__navbar__item,
                    .header__navbar__link {
                        width: 100%;
                        display: block;
                    }

                    .header__navbar__item {
                        padding: 1rem 1rem 1rem 0rem;
                        border-bottom: 1px solid #343638;
                    }

                    .header__navbar__link {
                        font-size: 1.5rem !important;
                    }
                }

            }

        }
    }
}