#contact {
    height: auto;
    @include flexCenter(center, flex-end, column);

    .contact__header {
        & .contact__header__title {
            text-align: center;
        }

        & .contact__header__text {
            text-align: center;
        }
    }

    .contact__form {
        width: 100%;
        max-width: 800px;
        margin-top: 15px;
        @include flexCenter(center, default, column);

        .contact__inputs {
            width: 100%;
            display: flex;

            & input:nth-child(even) {
                margin-left: 5px;
            }
        }

        .form__text,
        .form__message,
        #from__submit {
            width: 100%;
            height: 60px;
            padding: 15px;
            font-size: 1rem;
            color: #cccccc;
            margin-bottom: 5px;
            background-color: #171717;
            border: 1px solid #34363840;
        }

        #from__submit {
            cursor: pointer;
            transition: all ease-in-out 500ms;

            &:hover {
                color: #fff;
                background-color: #121212;
            }
        }

        .form__message {
            height: 200px;
        }

    }
}

@media only screen and (max-width: 500px) {
    #contact {
        padding-bottom: 0;

        .contact__form {

            .form__text {
                font-size: 1rem
            }

            .contact__inputs {
                & input:nth-child(even) {
                    margin-left: 0;
                }

                @include flexCenter(center, center, column);
            }
        }
    }
}

@media only screen and (max-width: 850px) {
    #contact {
        padding-bottom: 0;
    }
}