.technologies__cubes {
  z-index: 0;
  width: 50%;
  height: 500px;
  position: relative;
  @include flexCenter(center, center, row);

  .scene:nth-child(1),
  .scene:nth-child(2),
  .scene:nth-child(3) {
    width: 200px;
    height: 200px;
    perspective: 400px;
  }

  .scene:nth-child(1),
  .scene:nth-child(3) {
    position: absolute;
    transform: translate(50%, 50%);
  }

  // Outter
  .scene:nth-child(1) {
    // transform: scale(0);
    animation: fadeCubeOutter 1.4s linear;
    transition: transform ease-out 1.2s;

    &.--fadeincube {
      // transform: scale(2.3);
      transform: scale(230%);
      // opacity: 1;
    }
  }

  // Inner
  .scene:nth-child(2) {
    // transform: scale(0);
    animation: fadeCubeInner 1.8s linear;
    transition: transform ease-out 1.8s;

    &.--fadeincube {
      // transform: scale(1.45);
      transform: scale(145%);
    }
  }

  // InnerInner
  .scene:nth-child(3) {
    // transform: scale(0);
    animation: fadeCubeInnerInner 2s linear;
    transition: transform ease-out 2s;

    &.--fadeincube {
      // transform: scale(0.6);
      transform: scale(60%);
    }
  }


}

@import './_cubeFaces';

@import './_cubeAnimation';
