#home {
    padding-top: 0;
    overflow: hidden;
    margin-top: 55px;
    position: relative;
    @include flexCenter(center, flex-start, default);

    #homeCheckbox {
        display: none;
    }

    #homeCheckbox:checked~#side__icones .right__side__icones .side__icone__list .lang:not(.lang:first-child) {
        animation: dropDown 500ms;
        transform: translate3d(0, 0px, 0);
        width: 20px;
        height: 25px;
        opacity: 1;
    }

    #side__icones {
        .right__side__icones {
            top: 30px;
            display: block;
            position: absolute;

            .side__icone__list {
                padding: 0;
                height: auto;
                overflow: unset;
                justify-content: flex-start;

                .side__icone__item,
                .side__icone__item label,
                .side__icone__item .side__link {
                    width: 100px;
                    height: 50px;
                    @include flexCenter(center, center, row);
                }

                .side__link__image {
                    top: 0;
                    z-index: 9;
                    width: 40px;
                    height: 40px;
                    cursor: pointer;
                    position: absolute;
                }

                .lang {
                    cursor: pointer;
                    color: #636363;

                    &.active {
                        color: #fff;
                    }
                }

                .lang:not(.lang:first-child) {
                    opacity: 1;
                    width: 0px;
                    height: 0px;
                    overflow: hidden;
                    text-align: center;
                    transform: translate3d(0, -55px, 0);
                    transition: opacity ease-in-out 500ms, transform ease-in-out 500ms, width 500ms 100ms, height 500ms 100ms;
                }
            }

        }
    }

    .home__content {
        z-index: 5;
        @include flexCenter(flex-start, default, column);

        .home__content__title,
        .home__content__text {
            margin: 0 0 1rem 0;
        }

        .home__content__title {
            font-size: 6rem;
            height: min-content;
        }

        .home__content__text {
            a {
                font-weight: 700;
            }
        }
    }
}



@keyframes dropDown {
    to {
        opacity: 1;
        width: 20px;
        height: 25px;
        transform: translate3d(0, 0px, 0);
    }

    from {
        opacity: 0;
        width: 0px;
        height: 0px;
        transform: translate3d(0, -55px, 0);
    }
}

@media only screen and (max-width: 500px) {
    #home {

        .home__content {
            margin-bottom: 0;
            @include flexCenter(flex-start, center, column);

            .home__content__title {
                font-size: 4.2rem;
            }
        }
    }
}